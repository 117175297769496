import React from "react"

const Content = ({ children }) => (
    <section className={`content-wrap default primary`} >
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div 
                            className="content"
                            dangerouslySetInnerHTML={{ __html: children }}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
)


export default Content
