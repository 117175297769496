import React from "react"
import PropTypes from "prop-types"

import Logo from "../assets/svg/asana_travel_logo.svg"

import  "./layout.scss"

import Header from "../components/header";
import PageHeder from "../components/header-single"
import Section from "../components/section";
import Footer from "../components/footer";

const Layout = ({ children, page, source, type, headerStyle, headerHeight, section }) => {

    let wrapStyle = 'boxed';
    headerHeight = headerHeight || 'h35';
    headerStyle = headerStyle || 'default';

    return (
      <>
        <div className={`layout ` + type + ` ` + source + ` ` + headerHeight} >
            <Header Logo={Logo} wrapStyle={wrapStyle} />
            <PageHeder section={section} page={page} style={headerStyle}/>
            <Section>
              {children}
            </Section>
        </div>
        <Footer/>  
      </>
    )
  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
