import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Wrapper from '../templates/wrapper'
import Content from '../components/content/content-default'


Content.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}


const Page = ({ data }) => {
  const { wpPage: page } = data;

  let siteTitle = page && page.seo.title ? page.seo.title : `${page.title} `;

  return (
    <Layout page={page} type="boxed single" source="page">
      <Helmet encodeSpecialCharacters={false}>
          <meta charSet="utf-8" />
          <title>{siteTitle}</title>
      </Helmet>
      <Wrapper>
        <Content>
            {page.content}
        </Content>
      </Wrapper>
    </Layout>
  )
}


Page.propTypes = {
  data: PropTypes.object.isRequired,
}


export default Page


export const pageQuery = graphql`
  query PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      excerpt
      meta {
        metaTemplate
      }
      featuredImage {
        node {
            sourceUrl
            title
            altText
            srcSet
            mediaDetails {
              sizes {
                width
                height
                sourceUrl
              }
            }
        }
      }
      seo {
        title
      }
    }
  }
`
