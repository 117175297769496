import { Link } from "gatsby"
import React from "react"


import bg_default from "../assets/img/bg/calm_water.jpg"


const PageHeader = ({ page, section, style }) => {

    //WTF? TODO
    page = page ? page : { title: '', excerpt: '' }

    let featuredImage = page && page.featuredImage && page.featuredImage.node ? page.featuredImage.node.sourceUrl : bg_default;
    

    switch( style ) {
        case 'simple':
            var displayMeta = false;
        break;
        case 'article':
            var displayMeta = true;
        break;
        default:
            var displayMeta = false;
    }

    return (
        <section className={`single-header `} style={{backgroundImage: `url( ${featuredImage} )`}}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        {
                            displayMeta
                            ? <HeaderMeta section={section} page={page}/>
                            : null
                        }
                        <h1 className="title" itemprop="name">{page.title}</h1>
                        {page.excerpt ? <div className="excerpt" dangerouslySetInnerHTML={{ __html: page.excerpt }}></div> : null}
                    </div>
                </div>
            </div>
        </section>
    )

}

const HeaderMeta = ( {page, section} ) => {
    
    let topic = page.locationTopic && page.locationTopic.locationTopic ? `${String.fromCharCode(8226)} ${page.locationTopic.locationTopic}` : '';
    let readCount = page.content ? ` ${String.fromCharCode(8226)} ` + Math.round( page.content.replace(/(?:https?|http):\/\/[\n\S]+/g, '').length / 1000 ) + ` min read` : null;
        
    return (
        <div className="headerMeta">
            <div className="breadcrumbs">
                {section} {topic}
            </div>   
            <div className="date-time">
                {page.date}  {readCount} 
            </div>
        </div>
    )
}

export default PageHeader
